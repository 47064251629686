import React, { useEffect, useRef, useState } from 'react';
import type { CSSProperties } from 'react';
// Import Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { swiperInit } from '@helpers/swiper';
swiperInit();
import { useTranslation } from 'next-i18next';
import DOMPurify from 'isomorphic-dompurify';
import { clsx } from '@wonderful/wwc/dist/src/helpers/clsx';

import {
  InfographicProps,
  InfographicModuleProps,
  ChartProps,
  ChartItemProps,
  BulletProps,
} from './Infographic.types';
import { Breakpoints } from '@helpers/constants';
import { useIsRtl } from '@helpers/utils';

import Section from '@design/Section/Section';
import LazyBackground from '@design/LazyBackground/LazyBackground';
import BuilderEditingHelper from '@common/BuilderEditingHelper/BuilderEditingHelper';
import BuilderImage from '@common/BuilderImage/BuilderImage';
import styles from './Infographic.module.scss';

export default function Infographic(props: InfographicProps) {
  const { pistachios, almonds } = props;

  // Used to determine which infographic is rendered for Switch
  // false for Pistachios, true for Almonds
  const [isOn, setIsOn] = useState(false);

  const [isInViewport, setIsInViewport] = useState(false);
  const onEnterViewport = () => {
    setIsInViewport(true);
  };

  // For rendering changes to Builder
  // Will change isOn to true if only Almonds infographic exists so it is rendered
  useEffect(() => {
    !pistachios && almonds && setIsOn(true);
  }, [pistachios, almonds]);

  const { t } = useTranslation('common');

  const switchRowContents = (
    <>
      <button
        className={clsx(styles.switchButton, !isOn ? styles.whiteText : '')}
        onClick={() => setIsOn(false)}
        aria-label={t('pistachios')}
      >
        {t('pistachios')}
      </button>

      <Switch
        data-testid={'infographic-switch'}
        isOn={isOn}
        toggle={() => setIsOn(on => !on)}
        defaultToggleLabel={t('pistachios')}
        secondToggleLabel={t('almonds')}
      />

      <button
        className={clsx(styles.switchButton, isOn ? styles.whiteText : '')}
        onClick={() => setIsOn(true)}
        aria-label={t('almonds')}
      >
        {t('almonds')}
      </button>
    </>
  );

  // Render helper if Pistachios & Almonds infographics are empty
  if (!pistachios && !almonds) {
    return <BuilderEditingHelper componentName="Infographic" visible={true} />;
  }

  return (
    <Section
      viewportThreshold={0.01}
      className={clsx(
        styles.chartSection,
        isOn ? styles.almondsBG : styles.pistachiosBG,
        isInViewport ? styles.isInViewport : ''
      )}
      onEnterViewport={onEnterViewport}
    >
      {pistachios && almonds ? (
        <div className={clsx('container', styles.switchContainer)}>
          <div className={clsx(styles.desktopSwitchRow, 'switch')}>
            {switchRowContents}
          </div>
        </div>
      ) : null}

      <InfographicModule
        {...(isOn ? almonds : pistachios)}
        componentId={isOn ? 'almond-infographic' : 'pistachio-infographic'}
      />
    </Section>
  );
}

function InfographicModule(props: InfographicModuleProps) {
  const { title, subtitle, image, chart, bullet, footnote, componentId } =
    props;

  return (
    <div
      className={clsx('container', styles.innerContainer)}
      data-testid={componentId}
      key={componentId}
    >
      {title ? (
        <h2 className={styles.title} data-testid={`${componentId}-title`}>
          {title}
        </h2>
      ) : null}
      {subtitle ? (
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtitle) }}
          data-testid={`${componentId}-subtitle`}
        />
      ) : null}

      <div className={clsx(styles.chartContainer, 'row')}>
        {chart ? (
          <div
            className={styles.chartData}
            data-testid={`${componentId}-chart`}
          >
            <Chart {...chart} />
          </div>
        ) : null}

        {image && image.src ? (
          <div
            className={clsx(styles.chartImage)}
            data-testid={`${componentId}-image`}
          >
            <BuilderImage
              imageSrc={image.src}
              alt={image?.altText}
              mobileWidth={`${Breakpoints.md}px`}
              tabletWidth={`${Breakpoints.lg}px`}
              desktopWidth={`${Breakpoints.xxl}px`}
            />
            <span className={styles.shadow} />
          </div>
        ) : null}

        {bullet ? (
          <div
            className={clsx(styles.bulletData, styles.chartData)}
            data-testid={`${componentId}-bullet`}
          >
            <Bullet {...bullet} />
          </div>
        ) : null}

        {chart || bullet ? (
          <ChartSwiper
            chart={chart}
            bullet={bullet}
            componentId={componentId}
          />
        ) : null}
      </div>
      {footnote ? (
        <div className={styles.footnoteWrapper}>
          <div
            className={styles.footnote}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(footnote) }}
            data-testid={`${componentId}-footnote`}
          />
        </div>
      ) : null}
    </div>
  );
}

function Switch(props: {
  isOn: boolean;
  toggle: () => void;
  defaultToggleLabel: string;
  secondToggleLabel: string;
  'data-testid': string;
}) {
  const classNames = [styles.switch];

  if (props.isOn) {
    classNames.push(styles.isOn);
  }

  return (
    <button
      data-testid={props['data-testid'] ?? props['data-testid']}
      aria-label={`${
        props.isOn ? props.secondToggleLabel : props.defaultToggleLabel
      } infographic selected. Switch to see ${
        props.isOn ? props.defaultToggleLabel : props.secondToggleLabel
      } infographic.`}
      className={clsx(classNames)}
      onClick={() => {
        props.toggle();
      }}
    />
  );
}

function Chart(props: ChartProps) {
  const { title, subtitle, items, label } = props;
  return (
    <>
      {title ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(title),
          }}
          className={styles.h3}
        />
      ) : null}

      {subtitle ? (
        <div
          className={styles.chartSubtitle}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(subtitle),
          }}
        />
      ) : null}

      {items ? <ChartList items={items} label={label} /> : null}
    </>
  );
}

function Bullet(props: BulletProps) {
  const { title, bulletList } = props;

  return (
    <>
      {title ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(title),
          }}
          className={styles.h3}
        />
      ) : null}

      {bulletList ? (
        <ul>
          {bulletList?.map((bullet, index) => {
            return bullet.text ? (
              <li
                key={`bullet_list_${index}`}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(bullet.text),
                }}
              ></li>
            ) : null;
          })}
        </ul>
      ) : null}
    </>
  );
}

function ChartList(props: { items?: ChartItemProps[]; label?: string }) {
  const { items, label } = props;

  const { t } = useTranslation('common');

  const max =
    items && items?.length > 0
      ? items?.reduce(
          (max, item) => (item.grams && item.grams > max ? item.grams : max),
          0
        )
      : 0;

  const calculateWidth = (item: ChartItemProps) =>
    item.grams ? `calc(${(item.grams / max) * 100}% - 15px)` : '100%';

  return (
    <div className={styles.chartList}>
      <ul>
        {items
          ?.sort((first, second) =>
            first.grams && second.grams ? second.grams - first.grams : 0
          )
          .map((item, index) => (
            <li key={item.label}>
              <span>{item.label}</span>
              {item.grams ? (
                <div>
                  <span
                    className={styles.progress}
                    style={
                      {
                        minWidth: '60px',
                        width: calculateWidth(item),
                        '--transition-delay': (index + 1) * 0.1 + 's',
                      } as CSSProperties
                    }
                  >
                    {item.grams}
                    {t('g')}
                  </span>
                </div>
              ) : null}
            </li>
          ))}
      </ul>
      <hr />
      {label ? (
        <small>
          <p>{label}</p>
        </small>
      ) : null}
    </div>
  );
}

function ChartSwiper(props: {
  chart?: ChartProps;
  bullet?: BulletProps;
  componentId: string;
}) {
  const { chart, bullet, componentId } = props;

  const currentIndex = useRef<number>();
  const pagination = useRef<HTMLDivElement>(null);

  const isRtl = useIsRtl();

  return (
    <div className={styles.chartSwiperWrapper}>
      <Swiper
        className={styles.swiper}
        watchSlidesProgress
        slidesPerView={1}
        navigation={{
          prevEl: `#swiper-button-prev-${componentId}`,
          nextEl: `#swiper-button-next-${componentId}`,
        }}
        pagination={{
          clickable: true,
          el: `#swiper-pagination-${componentId}`,
          type: 'bullets',
        }}
        noSwiping={false}
        shortSwipes={false}
        loop
        onBeforeInit={swiper => {
          currentIndex.current = swiper.realIndex;
        }}
        onSlideChangeTransitionEnd={swiper => {
          if (currentIndex.current !== swiper.realIndex) {
            const activeSwiperElement = swiper.slides[
              swiper.activeIndex
            ]?.querySelector(`.${styles.chartWrapper}`) as HTMLElement;

            if (activeSwiperElement) {
              activeSwiperElement?.focus();
              currentIndex.current = swiper.realIndex;
            }
          }
        }}
        onSlideChange={swiper => {
          swiper?.pagination?.bullets?.forEach(function (bullet, index) {
            const isPressed = swiper.realIndex === index ? true : false;
            //@ts-ignore - TS not accepting these as HTMLSpanElement
            bullet.setAttribute('aria-pressed', isPressed);
          });
        }}
        dir={isRtl ? 'rtl' : 'ltr'}
      >
        <span className={styles.noHeight} slot="container-start">
          Slider with nutrition information
        </span>

        {chart ? (
          <SwiperSlide
            key={`${componentId}-1`}
            className={styles.chartSwiperData}
          >
            {({ isVisible }) => {
              return (
                <div
                  tabIndex={isVisible ? 0 : -1}
                  aria-hidden={!isVisible}
                  className={styles.chartWrapper}
                >
                  <label className={styles.noHeight}>Slide 1 of 2</label>
                  <Chart {...chart} />
                </div>
              );
            }}
          </SwiperSlide>
        ) : null}

        {bullet ? (
          <SwiperSlide
            key={`${componentId}-2`}
            className={styles.chartSwiperData}
          >
            {({ isVisible }) => {
              return (
                <div
                  tabIndex={isVisible ? 0 : -1}
                  aria-hidden={!isVisible}
                  className={styles.chartWrapper}
                >
                  <label className={styles.noHeight}>Slide 2 of 2</label>
                  <div className={styles.bulletData}>
                    <Bullet {...bullet} />
                  </div>
                </div>
              );
            }}
          </SwiperSlide>
        ) : null}
      </Swiper>
      <LazyBackground
        id={`swiper-button-prev-${componentId}`}
        className={isRtl ? styles.swiperButtonNext : styles.swiperButtonPrev}
        element="button"
        path="images/home/arrow_right_black.svg"
        aria-label="Previous Slide"
      />
      <LazyBackground
        id={`swiper-button-next-${componentId}`}
        className={isRtl ? styles.swiperButtonPrev : styles.swiperButtonNext}
        element="button"
        path="images/home/arrow_right_black.svg"
        aria-label="Next Slide"
      />
      <div id={`swiper-pagination-${componentId}`} ref={pagination}></div>
    </div>
  );
}
